<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="openAddProvider()"
          >Agregar proveedor</b-button
        >
      </div>

      <div class="control">
        <b-button
          type="is-primary"
          @click="startImport()"
          v-if="
            companyPaymentPlanModules.main.importProviders === 'S' &&
            permissions &&
            permissions.main.importProviders === 'S'
          "
          >Importar proveedores</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="startExport()"
          v-if="
            companyPaymentPlanModules.main.exportProviders === 'S' &&
            permissions &&
            permissions.main.exportProviders === 'S'
          "
          >Exportar proveedores</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container"></div>
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
    </div>

    <div class="table-header">
      Cantidad de Proveedores: <b>{{ providers.length }}</b>
    </div>
    <b-table
      pagination-position="both"
      :scrollable="true"
      :data="providers"
      :striped="true"
      :bordered="true"
      :hoverable="true"
      :checked-rows.sync="checkedProviders"
      :current-page="currentPage"
      :selected.sync="selected"
      ref="Providers"
      paginated
      :per-page="perPage"
      :opened-detailed="defaultOpenedDetails"
      @details-open="
        (row) =>
          $buefy.toast.open({
            message: `Información de ${row.NOMBRE_PRV}`,
            type: 'is-success',
            position: 'is-bottom',
          })
      "
      detailed
      detail-key="_id"
      sort-icon="arrow-up"
      sort-icon-size="is-small"
      default-sort-direction="asc"
      checkbox-position="left"
      default-sort="CLAVE_PRV"
    >
      <template #empty>
        <div class="has-text-centered">No hay proveedores</div>
      </template>

      <template slot-scope="props">
        <b-table-column
          v-for="column in columns"
          searchable
          sortable
          :visible="column.display"
          v-bind="column"
          :key="column.field"
          :label="column.label"
          :custom-sort="column.customSort"
        >
          <p
            v-if="!checkIfValid(props.row[column.field])"
            @click="openPage(column.label)"
          >
            {{ "Sin información" }}
          </p>
          <p
            v-if="column.money && checkIfValid(props.row[column.field])"
            @click="openPage(column.label)"
          >
            {{ props.row[column.field] | money("MXN", 2) }}
          </p>
          <p
            v-if="!column.money && checkIfValid(props.row[column.field])"
            @click="openPage(column.label)"
          >
            {{ props.row[column.field] }}
          </p>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <figure class="media-left">
            <p class="image is-64x64"></p>
          </figure>
          <div class="content">
            <AddProviderModal :providerInformation="props.row" />
          </div>
        </article>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import Cleave from "cleave.js";
import AddProviderModal from "./AddProviderModal";
import AddProviderBankAccount from "./AddProviderBankAccount";
import AddProviderContact from "./AddProviderContact";
import ImportFileModal from "../../Global/components/ImportFileModal";
import moment from "moment";
import Masks from "../../../data/cleave-masks";
import { EventBus } from "../../../event-bus";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

export default {
  name: "ProvidersList",
  components: {
    AddProviderModal,
  },
  directives: { cleave },
  data() {
    //Proveedores
    return {
      perPage: 50,
      checkedProviders: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_PRV",
          label: "Clave del proveedor",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortyByKey,
        },
        {
          field: "CLIENTE_C_PRV",
          label: "Número interno",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "TIPO_PRV_FOR",
          label: "Tipo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_PRV",
          label: "Nombre",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "OBSERVACIONES",
          label: "Observaciones",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DOMICIL_PRV",
          label: "Domicilio",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "EMAIL",
          label: "Email",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CEL_PRV",
          label: "Teléfono celular",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE",
          label: "Nombre de contacto",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CLABE_PRV",
          label: "CLABE",
          sortable: true,
          searchable: true,
          display: true,
        },

        {
          field: "BALANCE_FIN",
          label: "Estado de cuenta actual",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
      masks: Masks,
      refreshLoading: false,
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    EventBus.$on("sendProvidersContacts", (contacts, providerId) => {
      let finalProviders = this.providers.map((singleProvider) => {
        if (singleProvider._id === providerId) {
          // Saves new values in the store
          singleProvider.CONTACT = contacts;
          return singleProvider;
        }
        return singleProvider;
      });
      this.providers = finalProviders;
    });

    EventBus.$on("sendAddProviderBankAccount", (accounts, providerId) => {
      let finalProviders = this.providers.map((singleProvider) => {
        if (singleProvider._id === providerId) {
          // Saves new values in the store
          singleProvider.CUEN_BANC = accounts;
          return singleProvider;
        }
        return singleProvider;
      });
      this.providers = finalProviders;
    });
  },
  beforeDestroy() {
    EventBus.$off();
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETBANKS"));
      allPromises.push(await this.$store.dispatch("GETCLIENTS"));
      if (this.$store.getters.COMMERCIALBUSINESS.length === 0) {
        allPromises.push(this.$store.dispatch("GETCOMMERCIALBUSINESS"));
      }
      if (this.$store.getters.SATCFDIUSES.length === 0) {
        allPromises.push(this.$store.dispatch("GETSATCFDIUSES"));
      }
      if (this.$store.getters.SATREGIMENES.length === 0) {
        allPromises.push(this.$store.dispatch("GETSATREGIMENES"));
      }

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    confirmDeleteProviders() {
      if (this.checkedProviders.length > 0) {
        this.reloadInformation();
        this.$buefy.dialog.confirm({
          title: "Borrar proveedores",
          message:
            "¿Estas seguro que deseas <b>borrar</b> los proovedores seleccinados? Esta acción no puede ser cambiada.",
          confirmText: "Borrar proveedores",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () =>
            this.$store
              .dispatch("DELETEPROVIDERS", this.checkedProviders)
              .then((response) => {
                if (response === "Success") {
                  this.$buefy.dialog.confirm({
                    title: "Éxito",
                    message:
                      "Se borraron exitosamente los proveedores seleccionadas",
                    confirmText: "Entendido",
                    type: "is-success",
                    hasIcon: true,
                    cancelText: "cancelar",
                    canCancel: false,
                  });
                } else {
                  this.$buefy.dialog.confirm({
                    title: "Error",
                    message:
                      "Hubo un error al borrar los proveedores en la base de datos",
                    confirmText: "Entendido",
                    type: "is-danger",
                    hasIcon: true,
                    cancelText: "cancelar",
                    canCancel: false,
                  });
                }
              }),
        });
      } else {
        this.$buefy.dialog.confirm({
          title: "Atención",
          message: "No hay proveedores seleccionados",
          confirmText: "Entendido",
          hasIcon: true,
          type: "is-danger",
          onConfirm: () => {},
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openAddProvider() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddProviderModal,
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddProvidersBankAccounts(providerInfo) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddProviderBankAccount,
        props: {
          providersAccount: providerInfo.CUEN_BANC,
          providerId: providerInfo._id,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    openAddProvidersContact(providerInfo) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddProviderContact,
        props: {
          providersContacts: providerInfo.CONTACT,
          providerId: providerInfo._id,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    confirmExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación de proveedores",
        message: `¿Desea confirmar la exportación de los proveedores a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: () => this.exportData(),
      });
    },
    startImport() {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: ImportFileModal,
        props: {
          importType: "providers",
          nameImport: "Provedores",
          afterAction: "GETPROVIDERS",
        },
        hasModalCard: false,
        customClass: "import-provider-modal-class",
        trapFocus: true,
        width: "50%",
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    startExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación de proveedores",
        message: `¿Desea confirmar la exportación de los proveedores a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: () => this.exportData(),
      });
    },
    exportData() {
      let payload = {
        data: this.providers.map((singleProvider) => {
          const singleProviderFormatted = {};

          singleProviderFormatted["NUMERO INTERNO DEL PROVEEDOR (Requerido)"] =
            singleProvider.CLIENTE_C_PRV ? singleProvider.CLIENTE_C_PRV : "";
          singleProviderFormatted["CLAVE DEL PROVEEDOR (Requerido)"] =
            singleProvider.CLAVE_PRV ? singleProvider.CLAVE_PRV : "";
          singleProviderFormatted["NOMBRE (Requerido)"] =
            singleProvider.NOMBRE_PRV ? singleProvider.NOMBRE_PRV : "";
          singleProviderFormatted["RFC DEL PROVEEDOR (Opcional)"] =
            singleProvider.RFC_PRV ? singleProvider.RFC_PRV : "";
          singleProviderFormatted["CUENTA CONTABLE (Opcional)"] =
            singleProvider.CUENTA_CONTABLE_PROVEEDOR
              ? singleProvider.CUENTA_CONTABLE_PROVEEDOR
              : "";
          singleProviderFormatted["URL (Opcional)"] = singleProvider.URL_PRV
            ? singleProvider.URL_PRV
            : "";
          singleProviderFormatted["OBSERVACIONES (Opcional)"] =
            singleProvider.OBSERVACIONES ? singleProvider.OBSERVACIONES : "";
          singleProviderFormatted["DIRECCION (Opcional)"] =
            singleProvider.DOMICIL_PRV ? singleProvider.DOMICIL_PRV : "";
          singleProviderFormatted["CODIGO POSTAL (Opcional)"] =
            singleProvider.CP_PRV ? singleProvider.CP_PRV : "";
          singleProviderFormatted["NUMERO INTERIOR (Opcional)"] =
            singleProvider.NUM_INTER ? singleProvider.NUM_INTER : "";
          singleProviderFormatted["NUMERO EXTERIOR (Opcional)"] =
            singleProvider.NUM_EXTER ? singleProvider.NUM_EXTER : "";
          singleProviderFormatted["NOMBRE DE CONTACTO (Opcional)"] =
            singleProvider.NOMBRE ? singleProvider.NOMBRE : "";
          singleProviderFormatted["APELLIDO PATERNO DE CONTACTO (Opcional)"] =
            singleProvider.PATER ? singleProvider.PATER : "";
          singleProviderFormatted["APELLIDO MATERNO DE CONTACTO (Opcional)"] =
            singleProvider.MATER ? singleProvider.MATER : "";
          singleProviderFormatted["TELEFONO DE CONTACTO (Opcional)"] =
            singleProvider.TELS_PRV ? singleProvider.TELS_PRV : "";
          singleProviderFormatted["CELULAR DE CONTACTO (Opcional)"] =
            singleProvider.CEL_PRV ? singleProvider.CEL_PRV : "";
          singleProviderFormatted["EMAIL DE CONTACTO (Opcional)"] =
            singleProvider.EMAIL ? singleProvider.EMAIL : "";
          singleProviderFormatted["DIAS DE CREDITO (Requerido)"] =
            singleProvider.DIAS_CRD ? singleProvider.DIAS_CRD : 0;
          singleProviderFormatted["LIMITE DE CREDITO (Requerido)"] =
            singleProvider.LIMITE_CRD ? singleProvider.LIMITE_CRD : 0;
          singleProviderFormatted["PORCENTAJE DE DESCUENTO (Opcional)"] =
            singleProvider.PORC_DESCUENTO ? singleProvider.PORC_DESCUENTO : 0;

          return singleProviderFormatted;
        }),
        name: "proveedores-" + moment().format("l").toString(),
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
    validateEmail(email) {
      const re =
        //eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    openPage(label) {
      if (label === "Estado de cuenta actual") {
        this.$router.push({ path: "/treasury/Balances" }).catch(() => {});
        this.$store.dispatch(
          "CHANGENEWHINT",
          "En esta pantalla podrás consultar los estados de cuenta de tus clientes y proveedores"
        );
      }
    },
    sortyByKey(a, b, isAsc) {
      if (isAsc) {
        return a.CLAVE_PRV.localeCompare(b.CLAVE_PRV, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      } else {
        return b.CLAVE_PRV.localeCompare(a.CLAVE_PRV, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      }
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        let providerWithProps = { ...singleProvider };
        providerWithProps.soonPaymentActive = false;
        providerWithProps.creditActive = false;
        providerWithProps.cardActive = false;
        if (!singleProvider.TIPO_PRV) {
          providerWithProps.TIPO_PRV_FOR = "Proveedor/Acreedor";
        }

        if (singleProvider.TIPO_PRV === "PR") {
          providerWithProps.TIPO_PRV_FOR = "Proveedor";
        }

        if (singleProvider.TIPO_PRV === "AC") {
          providerWithProps.TIPO_PRV_FOR = "Acreedor";
        }

        if (singleProvider.TIPO_PRV === "PA") {
          providerWithProps.TIPO_PRV_FOR = "Proveedor/Acreedor";
        }
        return providerWithProps;
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
